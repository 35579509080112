exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-2-js": () => import("./../../../src/pages/blog-2.js" /* webpackChunkName: "component---src-pages-blog-2-js" */),
  "component---src-pages-blog-3-js": () => import("./../../../src/pages/blog-3.js" /* webpackChunkName: "component---src-pages-blog-3-js" */),
  "component---src-pages-blog-copy-js": () => import("./../../../src/pages/blog copy.js" /* webpackChunkName: "component---src-pages-blog-copy-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-single-1-js": () => import("./../../../src/pages/blog-single-1.js" /* webpackChunkName: "component---src-pages-blog-single-1-js" */),
  "component---src-pages-blog-single-2-js": () => import("./../../../src/pages/blog-single-2.js" /* webpackChunkName: "component---src-pages-blog-single-2-js" */),
  "component---src-pages-blog-single-3-js": () => import("./../../../src/pages/blog-single-3.js" /* webpackChunkName: "component---src-pages-blog-single-3-js" */),
  "component---src-pages-blog-single-4-js": () => import("./../../../src/pages/blog-single-4.js" /* webpackChunkName: "component---src-pages-blog-single-4-js" */),
  "component---src-pages-blog-single-5-js": () => import("./../../../src/pages/blog-single-5.js" /* webpackChunkName: "component---src-pages-blog-single-5-js" */),
  "component---src-pages-blog-single-6-js": () => import("./../../../src/pages/blog-single-6.js" /* webpackChunkName: "component---src-pages-blog-single-6-js" */),
  "component---src-pages-blog-single-7-js": () => import("./../../../src/pages/blog-single-7.js" /* webpackChunkName: "component---src-pages-blog-single-7-js" */),
  "component---src-pages-blog-single-copy-js": () => import("./../../../src/pages/blog-single copy.js" /* webpackChunkName: "component---src-pages-blog-single-copy-js" */),
  "component---src-pages-blog-single-js": () => import("./../../../src/pages/blog-single.js" /* webpackChunkName: "component---src-pages-blog-single-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index-2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-3-js": () => import("./../../../src/pages/index-3.js" /* webpackChunkName: "component---src-pages-index-3-js" */),
  "component---src-pages-index-4-js": () => import("./../../../src/pages/index-4.js" /* webpackChunkName: "component---src-pages-index-4-js" */),
  "component---src-pages-index-copy-js": () => import("./../../../src/pages/index copy.js" /* webpackChunkName: "component---src-pages-index-copy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-noslider-js": () => import("./../../../src/pages/index-noslider.js" /* webpackChunkName: "component---src-pages-index-noslider-js" */),
  "component---src-pages-index-tutto-js": () => import("./../../../src/pages/index-tutto.js" /* webpackChunkName: "component---src-pages-index-tutto-js" */),
  "component---src-pages-iscrizione-newsletter-richiesta-js": () => import("./../../../src/pages/iscrizione-newsletter-richiesta.js" /* webpackChunkName: "component---src-pages-iscrizione-newsletter-richiesta-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-portfolio-details-js": () => import("./../../../src/pages/portfolio-details.js" /* webpackChunkName: "component---src-pages-portfolio-details-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-rent-bikes-js": () => import("./../../../src/pages/rent-bikes.js" /* webpackChunkName: "component---src-pages-rent-bikes-js" */),
  "component---src-pages-rent-montagna-js": () => import("./../../../src/pages/rent-montagna.js" /* webpackChunkName: "component---src-pages-rent-montagna-js" */),
  "component---src-pages-rent-ski-js": () => import("./../../../src/pages/rent-ski.js" /* webpackChunkName: "component---src-pages-rent-ski-js" */),
  "component---src-pages-rent-snowboard-js": () => import("./../../../src/pages/rent-snowboard.js" /* webpackChunkName: "component---src-pages-rent-snowboard-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-storia-js": () => import("./../../../src/pages/storia.js" /* webpackChunkName: "component---src-pages-storia-js" */)
}

