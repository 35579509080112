export const onInitialClientRender = () => {

    // script di Curator
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.charset = 'UTF-8';
    script.src = 'https://cdn.curator.io/published/75ca9046-7299-4864-9ed6-19d91ef49ae7.js';
    document.body.appendChild(script);

  };

  export const onRenderBody = ({ setHeadComponents }) => {
    setHeadComponents([
      <link rel="icon" href="/favicon.ico" type="image/x-icon" key="favicon" />
    ])
  }